import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

export enum ETasksRoute {
  tasks = 'tasks',
  myTasks = 'myTasks',
  allTasks = 'allTasks',
  myTeams = 'myTeams',
  list = 'list',
  view = 'view',
  summary = 'summary',
  allTodos = 'allTodos',
  myTodos = 'myTodos',
}

CamRoutes.addRoute({
  key: ETasksRoute.tasks,
  url: 'tasks',
  children: [
    {
      key: ETasksRoute.summary,
      url: 'summary',
    },
    {
      key: ETasksRoute.myTasks,
      url: 'myTasks',
    },
    {
      key: ETasksRoute.myTeams,
      url: 'myTeams',
      children: [
        {
          key: ETasksRoute.list,
          url: 'list',
        },
        {
          key: ETasksRoute.tasks,
          url: 'tasks/:teamId',
        },
      ],
    },
    {
      key: ETasksRoute.allTasks,
      url: 'allTasks',
    },
    {
      key: ETasksRoute.allTodos,
      url: 'allTodos',
    },
    {
      key: ETasksRoute.myTodos,
      url: 'myTodos',
    },
    {
      key: ETasksRoute.view,
      url: 'view/:id',
    },
  ],
});

export const tasksRoutes: Routes = [
  {
    path: '',
    redirectTo: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.summary]),
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () => import('./pages/base.component').then(c => c.TasksPage),
    children: [
      {
        path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.summary]),
        loadComponent: () => import('./pages/tasks/dashboard/dashboard.component').then(c => c.DashboardPage),
      },
      {
        path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.myTasks]),
        loadComponent: () => import('./pages/tasks/my-tasks-list/my-tasks-list.component').then(c => c.MyTaskListPage),
      },
      {
        path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.allTasks]),
        loadComponent: () =>
          import('./pages/tasks/all-tasks-list/all-tasks-list.component').then(c => c.AllTasksListPage),
      },
      {
        path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.allTodos]),
        loadComponent: () =>
          import('./pages/tasks/all-todos-list/all-todos-list.component').then(c => c.AllTodosListPage),
      },
      {
        path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.myTodos]),
        loadComponent: () => import('./pages/tasks/my-todos-list/my-todos-list.component').then(c => c.MyTodosListPage),
      },
      {
        path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.myTeams]),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: CamRoutes.getAbsoluteUrl([ETasksRoute.tasks, ETasksRoute.myTeams, ETasksRoute.list]),
          },
          {
            path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.myTeams, ETasksRoute.list]),
            loadComponent: () => import('./pages/tasks/team-list/team-list.component').then(c => c.TeamListPage),
          },
          {
            path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.myTeams, ETasksRoute.tasks]),
            loadComponent: () => import('./pages/tasks/team-list/list/list.component').then(c => c.ListTeamTaskPage),
          },
        ],
      },
    ],
  },
  {
    path: CamRoutes.getUrl([ETasksRoute.tasks, ETasksRoute.view]),
    loadComponent: () => import('./pages/tasks/view/view.component').then(c => c.ViewPage),
  },
];
